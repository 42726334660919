<template>
    <div class="w-100 h-100">
        <div class="w-100 h-100" v-if="content_ready">
            <play-waiting-page :single="single" v-if="mode==='WAIT'" @quit="handlePlayFromWait" dark></play-waiting-page>
            <play-live-page :single="single" v-else-if="mode==='PLAY'" @quit="handleEndFromPlay"
                            @source="handleSourceUpdate" :flv="true" :hide_comment_window="true"
                            :comments="public_comments" :self="self_comments"
                            @comments="updateComments"></play-live-page>
            <play-end-page :single="single" v-else-if="mode==='END' && single.close_action != 0" dark></play-end-page>

            <play-live-page :single="single" v-else-if="mode==='END' && single.close_action == 0" closed
                            :comments="public_comments" :self="self_comments"></play-live-page>
        </div>
        <div v-else class="h-100">
            <play-error-page v-if="error" :single="single" :error="error" dark
                             @passcode="handlePasscode"></play-error-page>

            <div v-else class="flex-in-middle h-100" style="font-size: 28px;">
                <i class="el-icon-loading"></i>
                <span class="text-muted ml-3">{{ $t("MESSAGE.WAIT") }}</span>
            </div>
        </div>
        <get-httponly-token v-if="$org.httponly_cookie"></get-httponly-token>
    </div>
</template>

<script>
import PlayErrorPage from "@/components/play-error-page";
import PlayWaitingPage from "@/components/play-waiting-page";
import PlayLivePage from "@/components/play-live-page";
import PlayEndPage from "@/components/play-end-page";
import PlayMixin from  "../../views/mixins/play-mixin.js";
import GetHttponlyToken from "../../components/get-httponly-token.vue";
export default {
    mixins: [PlayMixin],
    components: {GetHttponlyToken, PlayWaitingPage, PlayErrorPage, PlayLivePage, PlayEndPage},
    name:"Play"
}
</script>

<style lang="scss">

</style>
